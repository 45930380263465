import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from './TableWithSummary/TableWithSummary';
import { formatNumberReadable } from '@helpers';
import { TextWithTooltipIcon } from '@components';
import { useSegment } from '../../../hooks/useSegment';

export const getDataShape = (type, wtt, ttw, wtw, tooltip) => ({
  type: { value: type, tooltip },
  wtt: { value: wtt },
  ttw: { value: ttw },
  wtw: { value: wtw }
});

export const emissionTableColumns = [
  { name: 'type', label: 'Type' },
  {
    name: 'wtt',
    label: 'WTT',
    tooltip: 'WTT: Well to Tank Emissions. Sometimes also referred as  energy production emission.'
  },
  {
    name: 'ttw',
    label: 'TTW',
    tooltip:
      'TTW: Tank to Wake emission. This is the emission due to fuel combustion in the vessel sometimes referred as operational emission.'
  },
  {
    name: 'wtw',
    label: 'WTW',
    tooltip:
      'WTW: Well to wake emission. This is the total emissions from energy production and operation (WTT +TTW) .'
  }
];

const Co2eEmissionTable = ({ myCargoHoc, myCargoToc, emissionSummary, isLoading }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([
      {
        items: { value: 'My Cargo (MT)' },
        amount: {
          value: formatNumberReadable(emissionSummary.myCargoTn?.toFixed(1)),
          isEditable: false
        }
      },
      {
        items: {
          value: 'TOC GHG Emission for my cargo (kg)',
          tooltip:
            'TOC ( Transport Operation Category: A TOC is a group of transport operations that share similar characteristics). TOC GHG Emission corresponds to GHG emission from transport operations that include laden and ballast voyages.'
        },
        amount: { value: formatNumberReadable(emissionSummary.tocGhgEmissionKg?.toFixed(0)) }
      },
      {
        items: {
          value: 'HOC GHG Emission for my cargo (kg)',
          tooltip:
            'HOC ( Hub Operation Category: A HOC is a group of hub operations that share similar characteristics). HOC GHG Emission corresponds to GHG emission from equipment at port during loading and discharging operations at ports.'
        },
        amount: { value: formatNumberReadable(emissionSummary.hocGhgEmissionKg?.toFixed(0)) }
      },
      {
        items: {
          value: 'Total GHG Emission for my cargo (kg)',
          tooltip: 'Total GHG Emission indicates the sum of TOC GHG emission, HOC GHG emission.'
        },
        amount: { value: formatNumberReadable(emissionSummary.totalGhgEmission?.toFixed(0)) }
      }
    ]);
  }, [emissionSummary]);

  const myCargoTableColumns = [
    {
      name: 'items',
      label: 'Items'
    },
    {
      name: 'amount',
      label: 'Amount'
    }
  ];

  const summary = [
    {
      label: 'Total GHG Emission for My Cargo (kg)',
      value: formatNumberReadable(emissionSummary.totalGhgEmission?.toFixed(0))
    }
  ];

  const myCargoTocEmissionTableColumns = [
    {
      name: 'voyageLeg',
      label: 'Voyage Leg'
    },
    {
      name: 'origin',
      label: 'Origin'
    },
    {
      name: 'destination',
      label: 'Destination'
    },
    {
      name: 'distanceKm',
      label: 'Distance (km)',
      tooltip:
        'For RORO vessels, ‘Distance’ corresponds to shortest distance between port pairs estimated by Esgian based on positional data of historical voyages of all vessels in the Esgian database. For MPP vessels, ‘Distance’ corresponds to actual distance travelled between port pairs based on the positional data of the vessel carrying the cargo.'
    },
    {
      name: 'myTocActivity',
      label: 'My TOC activity (T-km)',
      tooltip: 'TOC activity is the product of cargo weight (t) and distance (km).'
    },
    {
      name: 'energyProduction',
      label: 'Energy Production',
      tooltip:
        'CO₂e (kg) Emission from energy production (also referred to as Well to Tank (WTT) Emission) corresponding to the user input cargo weight'
    },
    {
      name: 'operational',
      label: 'Operational',
      tooltip:
        'CO₂e (kg)Emission from vessel operation (also referred to as Tank to Wake ( TTW) Emission) corresponding to the user input cargo weight'
    },
    {
      name: 'overall',
      label: 'Overall',
      tooltip:
        'Sum of CO₂e (kg) Emission from energy production and vessel operation (also referred to as Well to Wake (WTW) Emission) corresponding to the user input cargo weight'
    }
  ];

  const myCargoTocEmissionTableData =
    myCargoToc.voyageLegMyCargoEmissionIntensities?.map((d) => ({
      voyageLeg: { value: `#${d.voyageLeg}` },
      origin: { value: d.fromPortName },
      destination: { value: d.toPortName },
      distanceKm: { value: formatNumberReadable(d.distanceKm?.toFixed(0)) },
      myTocActivity: { value: formatNumberReadable(d.myTocActivity?.toFixed(0)) },
      energyProduction: { value: formatNumberReadable(d.ghgEmissionEneryProductionKg?.toFixed(0)) },
      operational: { value: formatNumberReadable(d.ghgEmissionOperationalKg?.toFixed(0)) },
      overall: { value: formatNumberReadable(d.ghgEmissionOverallKg?.toFixed(0)) }
    })) ?? [];

  const myCargoTocEmissionTableTotalRow = {
    voyageLeg: { value: 'Total' },
    origin: { value: '-' },
    destination: { value: '-' },
    ghcEmissionKgCo2e: { value: '-' },
    myTocActivity: { value: formatNumberReadable(myCargoToc.totalMyTocActivity?.toFixed(0)) },
    energyProduction: {
      value: formatNumberReadable(myCargoToc.totalGhgEmissionEneryProductionKg?.toFixed(0))
    },
    operational: {
      value: formatNumberReadable(myCargoToc.totalGhgEmissionOperationalKg?.toFixed(0))
    },
    overall: { value: formatNumberReadable(myCargoToc.totalGhgEmissionOverallKg?.toFixed(0)) }
  };

  const { isMPP } = useSegment();

  const myCargoTocEmissionTableBallastRow = {
    voyageLeg: { value: 'Ballast' },
    origin: { value: '-' },
    destination: { value: '-' },
    ghcEmissionKgCo2e: { value: '-' },
    myTocActivity: { value: '-' },
    energyProduction: { value: '-' },
    operational: { value: '-' },
    overall: { value: formatNumberReadable(Math.floor(myCargoToc.ballast)) }
  };

  const myCargoTocEmissionTableBallastPlusRow = {
    voyageLeg: { value: 'Total + Ballast' },
    origin: { value: '-' },
    destination: { value: '-' },
    ghcEmissionKgCo2e: { value: '-' },
    myTocActivity: { value: '-' },
    energyProduction: { value: '-' },
    operational: { value: '-' },
    overall: {
      value: formatNumberReadable(Math.floor(myCargoToc.totalGhgEmissionOverallPlusBallastKg))
    }
  };

  const finalDataRows = [...myCargoTocEmissionTableData, myCargoTocEmissionTableTotalRow];

  if (isMPP) {
    finalDataRows.push(myCargoTocEmissionTableBallastRow);
    finalDataRows.push(myCargoTocEmissionTableBallastPlusRow);
  }

  finalDataRows.forEach((row) => {
    if (row.overall.value === 'Infinity' || row.overall.value === '∞') {
      row.overall.value = '-';
    }
  });
  const myCargoHocEmissionTableColumns = [
    {
      name: 'portName',
      label: 'Port Name'
    },
    {
      name: 'operationalHocEmissionIntensityKgCo2eT',
      label: 'Operational HOC Emission intensity (kg CO₂e/T)',
      tooltip: `Operational HOC Emission intensity defines the GHG emission per ton cargo handled at corresponding port. Esgian assumes the origin and destination selected by the user as the port of load and port of discharge respectively. The emission intensities are based on the default value defined in the GLECC framework. For RORO vessel it assumed that the port doesn't use any equipment to handle the cargo.`
    },
    {
      name: 'hocActivityT',
      label: 'HOC activity (T)',
      tooltip: `HOC (Hub operations category) Activity corresponds to the weight of the cargo (the user input cargo weight (My cargo)) in tonnes.`
    },
    {
      name: 'ghcEmissionKgCo2e',
      label: 'GHG Emission (kg CO₂e)',
      tooltip: 'GHG Emission from Hub operations which includes loading and discharging.'
    }
  ];

  const myCargoHocEmissionTableData =
    myCargoHoc.voyageLegMyCargoHocEmissions?.map((d) => ({
      portName: { value: d.portName },
      operationalHocEmissionIntensityKgCo2eT: {
        value: d.operationalHocEmissionIntensity?.toFixed(2)
      },
      hocActivityT: { value: formatNumberReadable(d.hocActivity?.toFixed(1)) },
      ghcEmissionKgCo2e: { value: formatNumberReadable(d.hocGhgEmission?.toFixed(0)) }
    })) ?? [];

  return (
    <TableWithSummary
      title="My Cargo"
      columns={myCargoTableColumns}
      data={data}
      onUpdate={(rowIndex, columnName, newValue) => {
        const newData = [...data];
        newData[rowIndex][columnName].value = +newValue;
        setData(newData);
      }}
      summary={summary}
      detailsTableTitle="My Cargo TOC (Transport Operation Category) Emission"
      detailsTableColumns={myCargoTocEmissionTableColumns}
      detailsTableData={finalDataRows}
      secondaryDetailsTableTitle={
        <TextWithTooltipIcon
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '0.1em'
          }}
          labelVariant="subtitle1"
          label={'My Cargo HOC (Hub Operation Category) Emission'}
          tooltipText={
            'HOC ( Hub Operation Category: A HOC is a group of hub operations that share similar characteristics). HOC GHG Emission corresponds to GHG emission from equipment at port during loading and discharging operations at ports.'
          }
        />
      }
      secondaryDetailsTableColumns={myCargoHocEmissionTableColumns}
      secondaryDetailsTableData={myCargoHocEmissionTableData}
      isLoading={isLoading}
    />
  );
};

Co2eEmissionTable.propTypes = {
  myCargoToc: PropTypes.object,
  myCargoHoc: PropTypes.object,
  emissionSummary: PropTypes.object,
  totalEts: PropTypes.number,
  isLoading: PropTypes.bool
};

export default Co2eEmissionTable;
